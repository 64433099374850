export default function initFavoritesWidget() {
  var favoritesWidget = document.getElementsByClassName("js_favorites-widget")[0];
  var loggedOutWidget = document.getElementsByClassName("js_favorites-widget-logged-out")[0];
  var toFavoritesPageVignette = favoritesWidget === null || favoritesWidget === void 0 ? void 0 : favoritesWidget.getElementsByClassName("js_favorites-widget__read-more-vignette")[0];
  var logInBtn = loggedOutWidget === null || loggedOutWidget === void 0 ? void 0 : loggedOutWidget.getElementsByClassName("favorites-widget__create-account-button")[0];
  if (!toFavoritesPageVignette && !logInBtn) return;
  var _ref = toFavoritesPageVignette ? {
      btn: toFavoritesPageVignette,
      type: "toFavorites",
      href: "/trader/favoriter/"
    } : {
      btn: logInBtn,
      type: "CTA",
      href: "/konto/skapa-anvandare/"
    },
    btn = _ref.btn,
    type = _ref.type,
    href = _ref.href;
  btn === null || btn === void 0 || btn.addEventListener("click", function () {
    window.dataLayer.push({
      event: "Graphics Click",
      eventInfo: {
        label: "Click on Favorites Widget",
        position: "".concat(type, " Button"),
        target: href
      }
    });
  });
}