import forEach from "../../helpers/forEach";
import StockFilterDropdown from "./stockFilterDropdown";
export default function stockFilter() {
  var element = document.getElementsByClassName("js_stock-filter")[0];
  var submitButton = element === null || element === void 0 ? void 0 : element.getElementsByClassName("js_stock-filter__button")[0];
  var overlay = document.getElementsByClassName("js_stock-filter__overlay")[0];
  var columnHeaders = document.getElementsByClassName("js_instrument-table__column");
  var dropdowns = [];
  var dropdownElements = document.getElementsByClassName("js_stock-filter-dropdown");
  var addElement = function addElement(el, dd) {
    dd.push(new StockFilterDropdown(el, closeAll, showOverlay, updateConnectedDropdowns, updateSectorSelection));
  };
  forEach(dropdownElements, addElement, dropdowns);
  updateSectorSelection();
  overlay.addEventListener("click", function () {
    closeAll();
  });
  submitButton.addEventListener("click", function () {
    redirectUser();
  });
  var addColumnClickEvent = function addColumnClickEvent(el) {
    el.addEventListener("click", function () {
      var field = el.dataset.field;
      var columnIsActive = el.classList.contains("instrument-table__column--ascending") || el.classList.contains("instrument-table__column--descending");
      if (columnIsActive) {
        redirectUser(field, el.classList.contains("instrument-table__column--ascending"));
      } else {
        // name column should be sorted ascending while others should be descending
        redirectUser(field, field !== "name");
      }
    });
  };
  forEach(columnHeaders, addColumnClickEvent);
  function redirectUser(field, descending) {
    var baseUrl = new URL("/bors/aktier/", window.location.origin);
    baseUrl.search = createQueryString(field, descending);
    window.location.href = baseUrl;
  }
  function createQueryString(field, descending) {
    var queryString = "?";
    dropdowns.forEach(function (dropdown) {
      var queryArgs = dropdown.getQueryArguments();
      queryString += queryArgs ? queryArgs : "";
    });
    if (field) {
      queryString += "field=".concat(field, "&");
      queryString += descending ? "desc=true&" : "";
    } else {
      var descendingField = getElementField("instrument-table__column--descending");
      if (descendingField) {
        queryString += "field=".concat(descendingField, "&desc=true&");
      } else {
        queryString += "field=".concat(getElementField("instrument-table__column--ascending"), "&");
      }
    }
    queryString += "tab=".concat(getElementIndex("instrument-table-tab-menu__tab--active"));
    return queryString;
  }
  function getElementIndex(className) {
    var index = 0;
    var el = document.getElementsByClassName(className)[0];
    while (el && (el = el.previousSibling)) index++;
    return index;
  }
  function getElementField(className) {
    var el = document.getElementsByClassName(className)[0];
    if (!el) {
      return null;
    }
    return el.dataset.field;
  }
  function closeAll() {
    dropdowns.forEach(function (dropdown) {
      return dropdown.close();
    });
    overlay.classList.add("hidden");
  }
  function showOverlay() {
    overlay.classList.remove("hidden");
  }
  function updateConnectedDropdowns(countryIds) {
    dropdowns.forEach(function (dropdown) {
      return dropdown.update(countryIds);
    });
    updateSectorSelection();
  }
  function updateSectorSelection() {
    if (!dropdowns[1] || !dropdowns[2]) return;
    if (dropdowns[1].hasItemsWithNoSectors()) {
      dropdowns[2].disable();
    } else {
      dropdowns[2].enable();
    }
  }
}