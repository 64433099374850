export default function marketStartPage() {
  var container = document.getElementsByClassName("js_market-start")[0];
  if (CHANNEL_DESKTOP) marketTop(container);
}
function marketTop(container) {
  if (!container) return;
  var buttons = container.getElementsByClassName("market-start-top__tab-menu-item");
  if (!buttons) return;
  var activeClass = "market-start-top__tab-menu-item--active";
  var containers = container.getElementsByClassName("market-start-top__tab");
  var _loop = function _loop(i, len) {
    buttons[i].addEventListener("click", function () {
      if (buttons[i].classList.contains(activeClass)) return;
      for (var j = 0; j < len; j++) {
        buttons[j].classList.remove(activeClass);
        containers[j].classList.add("hidden");
      }
      containers[i].classList.remove("hidden");
      buttons[i].classList.add(activeClass);
    });
  };
  for (var i = 0, len = buttons.length; i < len; i++) {
    _loop(i, len);
  }
}