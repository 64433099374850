import chartSelectionDestroy from "./chartSelectionDestroy";
import chartSelectionFrame from "./chartSelectionFrame";
import chartSelectionLabel from "./chartSelectionLabel";
export default function chartSelection(graph, chart, point) {
  var _ref, _chart$pointMouseDown, _Number, _chart$pointMouseDown3, _ref3, _chart$pointMouseDown4;
  chartSelectionDestroy(graph);
  var rectWidth = (_ref = point.plotX - Number((_chart$pointMouseDown = chart.pointMouseDown) === null || _chart$pointMouseDown === void 0 ? void 0 : _chart$pointMouseDown.plotX)) !== null && _ref !== void 0 ? _ref : 0;
  var sign, x;
  if (rectWidth > 0) {
    var _ref2, _chart$pointMouseDown2;
    x = (_ref2 = graph.plotLeft + Number((_chart$pointMouseDown2 = chart.pointMouseDown) === null || _chart$pointMouseDown2 === void 0 ? void 0 : _chart$pointMouseDown2.plotX)) !== null && _ref2 !== void 0 ? _ref2 : 0;
    sign = 1;
  } else {
    x = graph.plotLeft + point.plotX;
    sign = -1;
  }
  var diff = (_Number = Number((point === null || point === void 0 ? void 0 : point.y) - ((_chart$pointMouseDown3 = chart.pointMouseDown) === null || _chart$pointMouseDown3 === void 0 ? void 0 : _chart$pointMouseDown3.y))) !== null && _Number !== void 0 ? _Number : 0;
  var percentDiff = "".concat(Math.round(((_ref3 = diff / Number((_chart$pointMouseDown4 = chart.pointMouseDown) === null || _chart$pointMouseDown4 === void 0 ? void 0 : _chart$pointMouseDown4.y)) !== null && _ref3 !== void 0 ? _ref3 : 0) * 10000) / 100, "%");
  if (!x || !diff || !percentDiff || !rectWidth || !graph.plotTop || !graph.plotSizeY || !point.plotX) return;
  graph.selectionFrame = chartSelectionFrame(graph, chart, point, x, sign);
  graph.label = chartSelectionLabel(graph, chart, diff, point, percentDiff, x, sign);
  graph.labelGroup.translate(-graph.label.width / 2, 0).toFront();
}