export default function crossSaleWidget() {
  var widget = document.getElementsByClassName("js_cross-sale-widget")[0];
  if (!widget) return;
  var widgetLinks = widget.getElementsByTagName("a");
  if (!(widgetLinks !== null && widgetLinks !== void 0 && widgetLinks.length)) return;
  var _loop = function _loop(i) {
    widgetLinks[i].addEventListener("click", function (e) {
      pushDataLayer(e, i + 1);
    });
  };
  for (var i = 0, len = widgetLinks.length; i < len; i++) {
    _loop(i);
  }
}
function pushDataLayer(e, i) {
  var link = e.target.closest("a");
  if (!link) return;
  window.dataLayer.push({
    event: "Graphics Click",
    eventInfo: {
      label: "Click on Link Energimarknaden",
      position: i === 5 ? "CTA Button" : "Linkpos ".concat(i),
      target: link.href
    }
  });
}