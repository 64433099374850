import { COLOR_BACKGROUND, COLOR_LABELS, COLOR_LINE, COLOR_NEG_DARK_BACKGROUND, COLOR_POS_DARK_BACKGROUND, COLOR_TOOLTIP_BACKGROUND, FONT_CHART } from "../../helpers/chartConstants";
import chartSelection from "./utils/chartSelection";
import chartSelectionDestroy from "./utils/chartSelectionDestroy";
import forEach from "../../helpers/forEach";
import { formatInstrumentDecimal } from "../../helpers/marketDataFormatter";
import { formatLongDateTime } from "../../helpers/dateTimeFormatter";
var OMX = 6486;
var CHART_POSITIVE_CLASS = "market-index-graph__header-change--positive";
var CHART_NEGATIVE_CLASS = "market-index-graph__header-change--negative";
var INDEX_ACTIVE_CLASS = "market-indexes__item--active";
export default function indexCharts() {
  var elements = document.getElementsByClassName("js_index-graph");
  forEach(elements, indexChart);
}
var highcharts;
function indexChart(element) {
  if (!element) return;
  highcharts = highcharts || window.Highcharts;
  if (!highcharts) return;
  var chart = window.Di.chart;
  if (!chart) return;
  var chartData = {};
  chart.ids.forEach(function (id, i) {
    chartData[id] = {
      name: chart.names[i],
      diffValue: chart.diffValue[i],
      positive: chart.positive[i],
      points: chart.pointLists[i]
    };
  });
  var allIndexes = document.getElementsByClassName("js_market-indexes__item");
  var initIndex = function initIndex(indexItem) {
    var insref = indexItem.getAttribute("data-id");
    var url = indexItem.getAttribute("data-url");
    var displayName = indexItem.getAttribute("data-display-name");
    var marketIndexGraph = document.getElementsByClassName("js_market-index-graph")[0];
    var linkTag = marketIndexGraph.getElementsByClassName("js_market-graph-link")[0];
    var valueTag = marketIndexGraph.getElementsByClassName("js_market-graph-value")[0];
    indexItem.addEventListener("click", function () {
      var remove = function remove(item) {
        return item.classList.remove(INDEX_ACTIVE_CLASS);
      };
      forEach(allIndexes, remove);
      indexItem.classList.add(INDEX_ACTIVE_CLASS);
      linkTag.href = url;
      linkTag.innerHTML = displayName;
      valueTag.innerHTML = chartData[insref].diffValue;
      if (chartData[insref].positive) {
        valueTag.classList.add(CHART_POSITIVE_CLASS);
        valueTag.classList.remove(CHART_NEGATIVE_CLASS);
      } else {
        valueTag.classList.add(CHART_NEGATIVE_CLASS);
        valueTag.classList.remove(CHART_POSITIVE_CLASS);
      }
      initChart(chartData[insref], element);
    });
  };
  forEach(allIndexes, initIndex);
  initChart(chartData[OMX], element);
}
function initChart(chart, element) {
  /* eslint-disable prefer-arrow-callback, space-before-function-paren */
  var graph = highcharts.chart(element.id, {
    chart: {
      backgroundColor: COLOR_BACKGROUND,
      height: CHANNEL_MOBILE ? 170 : CHANNEL_TABLET ? 220 : 255,
      events: {
        load: function load() {
          this.labelGroup = this.renderer.g("labelGroup").add();
          var graphContainer = document.getElementsByClassName("js_market-index-graph")[0];
          graphContainer === null || graphContainer === void 0 || graphContainer.addEventListener("mouseup", function () {
            chart.mouseDown = false;
            chartSelectionDestroy(graph);
          });
        }
      }
    },
    title: {
      text: "",
      margin: 0
    },
    plotOptions: {
      series: {
        point: {
          events: {
            mouseOver: function mouseOver() {
              if (chart.mouseDown) chartSelection(graph, chart, this);
            },
            mousedown: function mousedown() {
              chart.mouseDown = true;
              chart.pointMouseDown = this;
            }
          }
        },
        color: chart.positive ? COLOR_POS_DARK_BACKGROUND : COLOR_NEG_DARK_BACKGROUND,
        lineWidth: 2,
        marker: {
          enabled: false
        }
      }
    },
    series: getSeries(chart),
    time: {
      useUTC: false
    },
    tooltip: {
      borderWidth: 0,
      backgroundColor: COLOR_TOOLTIP_BACKGROUND,
      style: {
        fontFamily: FONT_CHART
      },
      formatter: function formatter() {
        var date = new Date(this.x);
        var displayValue = this.y;
        return "<strong>".concat(formatLongDateTime(date), "</strong><br/>").concat(this.point.series.name, ": ").concat(formatInstrumentDecimal(displayValue));
      }
    },
    legend: {
      enabled: false
    },
    xAxis: {
      type: "datetime",
      lineColor: COLOR_LINE,
      gridLineColor: COLOR_LINE,
      tickColor: COLOR_LINE,
      gridLineDashStyle: "dash",
      gridLineWidth: 1,
      labels: {
        style: {
          color: COLOR_LABELS,
          fontFamily: FONT_CHART
        }
      }
    },
    yAxis: {
      title: {
        text: ""
      },
      opposite: true,
      gridLineColor: COLOR_LINE,
      gridLineWidth: 1,
      labels: {
        format: "{value}",
        align: "left",
        x: 5,
        reserveSpace: true,
        padding: 10,
        style: {
          color: COLOR_LABELS,
          fontFamily: FONT_CHART
        }
      }
    },
    credits: {
      enabled: false
    }
  });
  /* eslint-enable prefer-arrow-callback, space-before-function-paren */

  function getSeries(data) {
    return [{
      data: data.points,
      name: data.name,
      type: "area",
      threshold: null,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1
        },
        stops: [[0, data.positive ? COLOR_POS_DARK_BACKGROUND : COLOR_NEG_DARK_BACKGROUND], [1, "rgba(8, 0, 47, 0.1)"]]
      }
    }];
  }
  return graph;
}