import forEach from "../../helpers/forEach";
import smoothScrollPolyfill from "../../smoothScrollPolyfill";
smoothScrollPolyfill();
export default function stockwatch() {
  var letters = document.getElementsByClassName("js_stockwatch-letter");
  var scrollIntoView = function scrollIntoView(letter) {
    letter.addEventListener("click", function () {
      var letterTarget = document.getElementById("stockwatch-".concat(letter.id));
      if (!letterTarget) return;
      letterTarget.scrollIntoView({
        behavior: "smooth"
      });
    });
  };
  forEach(letters, scrollIntoView);
}