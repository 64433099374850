import { COLOR_NEG_DARK_BACKGROUND, COLOR_NEG_LIGHT_BACKGROUND, COLOR_NEUTRAL, COLOR_POS_DARK_BACKGROUND, COLOR_POS_LIGHT_BACKGROUND } from "./chartConstants";
import { formatInstrumentDecimal, formatInstrumentPercentage } from "./marketDataFormatter";
function getColor(value, element) {
  var positiveColor = element === "line" ? COLOR_POS_DARK_BACKGROUND : COLOR_POS_LIGHT_BACKGROUND;
  var negativeColor = element === "line" ? COLOR_NEG_DARK_BACKGROUND : COLOR_NEG_LIGHT_BACKGROUND;
  return value === 0 ? COLOR_NEUTRAL : value > 0 ? positiveColor : negativeColor;
}
function calculateDiffPrc(hoveredPoint, lastDataPoint) {
  if (!hoveredPoint || !lastDataPoint) return {};
  var developmentPrc = (lastDataPoint - hoveredPoint) / hoveredPoint * 100;
  return {
    value: formatInstrumentPercentage(developmentPrc),
    lineColor: getColor(developmentPrc, "line"),
    textColor: getColor(developmentPrc, "text")
  };
}
function calculateDiffLocalCurrency(hoveredPoint, lastDataPoint) {
  if (!hoveredPoint || !lastDataPoint) return {};
  var development = lastDataPoint - hoveredPoint;
  return {
    value: formatInstrumentDecimal(development, true),
    textColor: getColor(development, "text")
  };
}
export { calculateDiffLocalCurrency, calculateDiffPrc };