import { calculateDiffPrc } from "../../../helpers/calculateDiff";
export default function chartDevelopmentLine(graph, chart, point) {
  var development = calculateDiffPrc(point.y, chart.lastDataPointY);
  var developmentLine = graph.get("development-line");
  if (!developmentLine) {
    graph.addSeries({
      id: "development-line",
      name: "development-line",
      data: [[point.x, point.y], [chart.lastDataPointX, chart.lastDataPointY]],
      animation: false,
      dashStyle: "Dash",
      color: development.lineColor,
      enableMouseTracking: false
    });
  } else {
    developmentLine.data[0].update([point.x, point.y]);
    developmentLine.update({
      color: development.lineColor
    });
  }
}