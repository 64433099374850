import forEach from "../../helpers/forEach";
export default function currencyConverter() {
  var currencyConverterWrappers = document.getElementsByClassName("js_currency-converter");
  forEach(currencyConverterWrappers, converter);
}
function converter(element) {
  var leftButton = element.getElementsByClassName("js_currency-converter__button--left")[0];
  var leftDropdown = element.getElementsByClassName("js_currency-converter__dropdown--left")[0];
  var leftDropdownBackground = element.getElementsByClassName("js_currency-converter__dropdown-background--left")[0];
  var rightButton = element.getElementsByClassName("js_currency-converter__button--right")[0];
  var rightDropdown = element.getElementsByClassName("js_currency-converter__dropdown--right")[0];
  var rightDropdownBackground = element.getElementsByClassName("js_currency-converter__dropdown-background--right")[0];
  var leftInputContainer = element.getElementsByClassName("js_currency-converter__input")[0];
  var rightInputContainer = element.getElementsByClassName("js_currency-converter__input")[1];
  var leftInputUnit = leftInputContainer.getElementsByClassName("js_currency-converter__input--unit")[0];
  var leftInputField = leftInputContainer.getElementsByClassName("js_currency-converter__input--value")[0];
  var rightInputUnit = rightInputContainer.getElementsByClassName("js_currency-converter__input--unit")[0];
  var rightInputField = rightInputContainer.getElementsByClassName("js_currency-converter__input--value")[0];
  var swapCurrencyButton = element.getElementsByClassName("converter-mid")[0];
  var overlay = document.getElementsByClassName("js_converter-overlay")[0];
  var listItems = element.getElementsByClassName("js_dropdown-list__item");
  leftButton.addEventListener("click", function () {
    return openDropdown(leftDropdown, leftButton, leftDropdownBackground);
  });
  rightButton.addEventListener("click", function () {
    return openDropdown(rightDropdown, rightButton, rightDropdownBackground);
  });
  overlay.addEventListener("click", closeAll);
  function openDropdown(dropdown, button, background) {
    var dropdownIsHidden = dropdown.classList.contains("hidden");
    closeAll();
    if (dropdownIsHidden) {
      dropdown.classList.remove("hidden");
      background.classList.remove("hidden");
      overlay.classList.remove("hidden");
      button.classList.add("currency-converter__button--active");
    } else {
      dropdown.classList.add("hidden");
      background.classList.add("hidden");
      overlay.classList.add("hidden");
      button.classList.remove("currency-converter__button--active");
    }
  }
  leftInputField.addEventListener("input", calculateTransformation);
  var updateCurrencies = function updateCurrencies(item) {
    var side = item.getAttribute("data-side");
    var button = side === "left" ? leftButton : rightButton;
    var buttonContent = button.getElementsByClassName("js_currency-converter__button-wrapper")[0];
    var otherButton = side !== "left" ? leftButton : rightButton;
    item.addEventListener("click", function () {
      if (buttonContent && otherButton.innerHTML !== item.innerHTML) {
        buttonContent.innerHTML = item.innerHTML;
        closeAll();
        updateInputFields();
      }
    });
  };
  forEach(listItems, updateCurrencies);
  swapCurrencyButton.addEventListener("click", function () {
    var _ref = [rightButton.innerHTML, leftButton.innerHTML];
    leftButton.innerHTML = _ref[0];
    rightButton.innerHTML = _ref[1];
    updateInputFields();
    forEach(listItems, updateCurrencies);
  });
  function updateInputFields() {
    updateValue(leftButton, rightButton, leftInputField);
    updateValue(rightButton, leftButton, rightInputField);
    updateUnit(leftButton, leftInputUnit, leftInputField);
    updateUnit(rightButton, rightInputUnit, rightInputField);
    calculateTransformation();
  }
  function updateValue(main, sub, field) {
    var mainValue = parseFloat(main.getElementsByClassName("js_span-flag-text")[0].getAttribute("data-rate"));
    var subValue = parseFloat(sub.getElementsByClassName("js_span-flag-text")[0].getAttribute("data-rate"));
    var valueToSek = mainValue / subValue;
    field.setAttribute("data-rate", valueToSek);
  }
  function updateUnit(button, inputUnit, inputField) {
    var unit = button.getElementsByClassName("js_span-flag-text")[0].getAttribute("data-currency");
    inputUnit.innerHTML = unit;
    inputField.setAttribute("data-currency", unit);
  }
  function calculateTransformation() {
    var rate = leftInputField.getAttribute("data-rate");
    var value = leftInputField.value;
    rightInputField.value = (rate * value).toFixed(2);
  }
  function closeAll() {
    leftDropdown.classList.add("hidden");
    leftDropdownBackground.classList.add("hidden");
    rightDropdown.classList.add("hidden");
    overlay.classList.add("hidden");
    rightDropdownBackground.classList.add("hidden");
    leftButton.classList.remove("currency-converter__button--active");
    rightButton.classList.remove("currency-converter__button--active");
  }
}