import { CHART_SELECTION_GRADIENT } from "../../../helpers/chartConstants";
export default function chartSelectionFrame(graph, chart, point, x, sign) {
  var _ref, _chart$pointMouseDown;
  return graph.renderer.rect(x,
  // x
  graph.plotTop,
  // y
  ((_ref = point.plotX - Number((_chart$pointMouseDown = chart.pointMouseDown) === null || _chart$pointMouseDown === void 0 ? void 0 : _chart$pointMouseDown.plotX)) !== null && _ref !== void 0 ? _ref : 0) * sign,
  // width
  graph.plotSizeY // height
  ).attr({
    fill: CHART_SELECTION_GRADIENT,
    zIndex: -1
  }).add();
}