import { calculateDiffLocalCurrency, calculateDiffPrc } from "../../../helpers/calculateDiff";
import { formatInstrumentDecimal, formatInstrumentPercentage } from "../../../helpers/marketDataFormatter";
import { formatLongDateTime } from "../../../helpers/dateTimeFormatter";
import showHoursInChart from "../../../helpers/showHoursInChart";
export default function chartTooltip(_ref, instName, currency, isInterestRate) {
  var chart = _ref.chart,
    chartPoints = _ref.chartPoints,
    point = _ref.point;
  var displayValue = point.y;
  var hoveredDate = new Date(point.x);
  var showHours = showHoursInChart(chartPoints);
  var formattedDisplayValue = isInterestRate ? formatInstrumentPercentage(displayValue, false) : formatInstrumentDecimal(displayValue);
  var baseTooltip = "<p style=\"padding-bottom: 6px;\"><strong>".concat(formatLongDateTime(hoveredDate, showHours), "</p>\n  ").concat(instName, ": </strong> ").concat(formattedDisplayValue, "<br/>");
  if (!chart.showDevelopmentLine) return baseTooltip;
  var devPrc = calculateDiffPrc(point.y, chart.lastDataPointY);
  var devCurrency = calculateDiffLocalCurrency(point.y, chart.lastDataPointY);
  var devPrcElement = devPrc.value ? "<span style=\"color:".concat(devPrc.textColor, "\"><strong>").concat(devPrc.value, "</strong></span><br/>") : "";
  var devCurrencyElement = devCurrency.value && currency ? "<span style=\"color:".concat(devCurrency.textColor, "\"><strong>").concat(devCurrency.value, " ").concat(chart.currency, "</strong></span></br>") : "";
  return "".concat(baseTooltip).concat(devPrcElement).concat(devCurrencyElement);
}