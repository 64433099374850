function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
export { activateOverlay, closeOtherElms };
function activateOverlay(resetInputFn) {
  var overlay = document.getElementsByClassName("js_di-plus-pro-settings__overlay")[0];
  if (!overlay) return;
  overlay.classList.remove("hidden");
  overlay.addEventListener("click", function () {
    overlay.classList.add("hidden");
    resetInputFn();
  });
}
function closeOtherElms(previewClassName, inputClassName) {
  var dropdowns = document.getElementsByClassName("js_filter-dropdown");
  if ((dropdowns === null || dropdowns === void 0 ? void 0 : dropdowns.length) > 0) {
    var _iterator = _createForOfIteratorHelper(dropdowns),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var dropdown = _step.value;
        var list = dropdown.getElementsByClassName("js_filter-dropdown__list")[0];
        if (!(list !== null && list !== void 0 && list.classList.contains("hidden"))) {
          var _dropdown$getElements, _dropdown$getElements2;
          list.classList.add("hidden");
          dropdown.classList.remove("filter-dropdown--active");
          (_dropdown$getElements = dropdown.getElementsByClassName("js_filter-dropdown__background")[0]) === null || _dropdown$getElements === void 0 || _dropdown$getElements.classList.add("hidden");
          (_dropdown$getElements2 = dropdown.getElementsByClassName("js_filter-dropdown__button")[0]) === null || _dropdown$getElements2 === void 0 || _dropdown$getElements2.classList.remove("filter-button__arrow-up");
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }
  var searchPreview = document.getElementsByClassName(previewClassName)[0];
  if (!(searchPreview !== null && searchPreview !== void 0 && searchPreview.classList.contains("hidden"))) {
    searchPreview.classList.add("hidden");
    var searchInput = document.getElementsByClassName(inputClassName)[0];
    searchInput.value = "";
  }
}