import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { addRowClickEvents, getTableRows } from "./instrumentTableGeneral";
import setupLazyLoad from "../../helpers/setupLazyLoad";
export default function instrumentTableLazyLoad() {
  var triggerElement = document.getElementsByClassName("js_instrument-list__lazy-load-trigger")[0];
  if (!triggerElement) return;
  var offset = triggerElement.dataset.offset;
  setupLazyLoad(triggerElement, loadMoreInstruments);
  function loadMoreInstruments() {
    var searchString = window.location.search || "";
    var fetchUrl = "".concat(window.location.pathname, "load-more/").concat(searchString !== null && searchString !== void 0 && searchString.length ? "".concat(searchString, "&") : "?", "offset=").concat(offset);
    window.fetch(fetchUrl).then(function (response) {
      return response.text();
    }).then(function (result) {
      var loadWrapper = document.createElement("div");
      loadWrapper.innerHTML = result.trim();
      var tableAddOns = loadWrapper.getElementsByTagName("table");
      var allTabTableParts = document.getElementsByClassName("js_tab-menu__tab-target");
      [0, 1, 2].forEach(function (i) {
        var currentTabTables = [].slice.call(allTabTableParts).filter(function (t) {
          return t.dataset.tab === "table_".concat(i);
        });
        var lastTable = currentTabTables[currentTabTables.length - 1];
        var tableAddOn = tableAddOns[i];
        if (!lastTable || !tableAddOn) return;
        var newRows = getTableRows([tableAddOn]);
        if (CHANNEL_DESKTOP) {
          addRowsToDesktopTables(lastTable, newRows);
        } else {
          addRowsToTouchDeviceTable(lastTable, newRows);
        }
      });
      var dataWrapper = loadWrapper.getElementsByClassName("js_loaded-instruments")[0];
      if (dataWrapper) {
        offset = dataWrapper.dataset.offset;
        if (dataWrapper.dataset.showLoadMore === "false") triggerElement.remove();
      }
    })["catch"](function () {});
  }
  function addRowsToDesktopTables(table, newRows) {
    addRowClickEvents(newRows);
    var tbody = table.getElementsByTagName("tbody")[0];
    newRows.forEach(function (r) {
      tbody.appendChild(r);
    });
  }
  function addRowsToTouchDeviceTable(table, newRows) {
    var fixedTable = table.getElementsByClassName("js_instrument-table__fixed-part")[0];
    var scrollableTable = table.getElementsByClassName("js_instrument-table-scroll")[0];
    if (!fixedTable || !scrollableTable) return;
    var fixedTbody = fixedTable.getElementsByTagName("tbody")[0];
    var scrollableTbody = scrollableTable.getElementsByTagName("tbody")[0];
    if (!fixedTbody || !scrollableTbody) return;
    newRows.forEach(function (r) {
      var _r$childNodes;
      if ((r === null || r === void 0 || (_r$childNodes = r.childNodes) === null || _r$childNodes === void 0 ? void 0 : _r$childNodes.length) < 2) return;
      var fixedTr = document.createElement("tr");
      var scrollableTr = document.createElement("tr");
      fixedTr.appendChild(r.childNodes[0]);
      _toConsumableArray(r.childNodes).forEach(function (td) {
        scrollableTr.appendChild(td);
      });
      fixedTbody.appendChild(fixedTr);
      scrollableTbody.appendChild(scrollableTr);
      addRowClickEvents([fixedTr], [scrollableTr]);
    });
  }
}