import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { addRowClickEvents, getTableRows } from "./instrumentTableGeneral";
import forEach from "../../helpers/forEach";
import setupLazyLoad from "../../helpers/setupLazyLoad";
export default function calendarLazyLoadHandler() {
  if (window.Di.isFailover) return;
  var loadMoreTrigger = document.getElementsByClassName("js_load-more-calendar-events-trigger")[0];
  var listOffset = loadMoreTrigger.dataset.listOffset;
  setupLazyLoad(loadMoreTrigger, loadMoreEvents);
  var areAllReportEventsLoaded = false;
  var areAllMeetingEventsLoaded = false;
  var areAllDividendEventsLoaded = false;
  setupTriggerVisibilityToggle();
  function loadMoreEvents() {
    var _document$getElements;
    var activeTarget = (_document$getElements = document.getElementsByClassName("instrument-table-tab-menu__tab--active")[0]) === null || _document$getElements === void 0 || (_document$getElements = _document$getElements.dataset) === null || _document$getElements === void 0 ? void 0 : _document$getElements.target;
    if (areAllTabEventsLoaded(activeTarget)) return;
    var searchString = window.location.search;
    var fetchUrl = "/kalendern/load-more-events/".concat(searchString !== null && searchString !== void 0 && searchString.length ? "".concat(searchString, "&") : "?");
    fetchUrl += "listoffset=".concat(listOffset);
    window.fetch(fetchUrl).then(function (response) {
      return response.text();
    }).then(function (result) {
      var loadWrapper = document.createElement("div");
      loadWrapper.innerHTML = result.trim();
      var tableAddOns = loadWrapper.getElementsByTagName("table");
      var allTabTableParts = document.getElementsByClassName("js_tab-menu__tab-target");
      [0, 1, 2].forEach(function (i) {
        var currentTabTables = [].slice.call(allTabTableParts).filter(function (t) {
          return t.dataset.tab === "table_".concat(i);
        });
        var lastTable = currentTabTables[currentTabTables.length - 1];
        var tableAddOn = tableAddOns[i];
        if (!lastTable || !tableAddOn) return;
        var newRows = getTableRows([tableAddOn]);
        if (CHANNEL_DESKTOP) {
          addRowsToDesktopTables(lastTable, newRows);
        } else {
          addRowsToTouchDeviceTable(lastTable, newRows);
        }
      });
      var dataWrapper = loadWrapper.getElementsByClassName("js_loaded-events")[0];
      if (dataWrapper) {
        listOffset = dataWrapper.dataset.listOffset;
        var _dataWrapper$dataset = dataWrapper.dataset,
          hideReportsLoadMore = _dataWrapper$dataset.hideReportsLoadMore,
          hideMeetingsLoadMore = _dataWrapper$dataset.hideMeetingsLoadMore,
          hideDividendsLoadMore = _dataWrapper$dataset.hideDividendsLoadMore;
        if (hideReportsLoadMore === "true") {
          areAllReportEventsLoaded = true;
          if (activeTarget === "table_0") toggleTriggerVisibility(true);
        }
        if (hideMeetingsLoadMore === "true") {
          areAllMeetingEventsLoaded = true;
          if (activeTarget === "table_1") toggleTriggerVisibility(true);
        }
        if (hideDividendsLoadMore === "true") {
          areAllDividendEventsLoaded = true;
          if (activeTarget === "table_2") toggleTriggerVisibility(true);
        }
        if (hideReportsLoadMore === "true" && hideMeetingsLoadMore === "true" && hideDividendsLoadMore === "true") {
          loadMoreTrigger.remove();
        }
      }
    })["catch"](function () {});
  }
  function addRowsToDesktopTables(table, newRows) {
    addRowClickEvents(newRows);
    var tbody = table.getElementsByTagName("tbody")[0];
    newRows.forEach(function (r) {
      tbody.appendChild(r);
    });
  }
  function addRowsToTouchDeviceTable(table, newRows) {
    var fixedTable = table.getElementsByClassName("js_instrument-table__fixed-part")[0];
    var scrollableTable = table.getElementsByClassName("js_instrument-table-scroll")[0];
    if (!fixedTable || !scrollableTable) return;
    var fixedTbody = fixedTable.getElementsByTagName("tbody")[0];
    var scrollableTbody = scrollableTable.getElementsByTagName("tbody")[0];
    if (!fixedTbody || !scrollableTbody) return;
    newRows.forEach(function (r) {
      var _r$childNodes;
      if ((r === null || r === void 0 || (_r$childNodes = r.childNodes) === null || _r$childNodes === void 0 ? void 0 : _r$childNodes.length) < 2) return;
      var fixedTr = document.createElement("tr");
      var scrollableTr = document.createElement("tr");
      fixedTr.appendChild(r.childNodes[0]);
      _toConsumableArray(r.childNodes).forEach(function (td) {
        scrollableTr.appendChild(td);
      });
      fixedTbody.appendChild(fixedTr);
      scrollableTbody.appendChild(scrollableTr);
      addRowClickEvents([fixedTr], [scrollableTr]);
    });
  }
  function areAllTabEventsLoaded(target) {
    if (target === "table_0" && areAllReportEventsLoaded) {
      return true;
    } else if (target === "table_1" && areAllMeetingEventsLoaded) {
      return true;
    } else if (target === "table_2" && areAllDividendEventsLoaded) {
      return true;
    }
    return false;
  }
  function setupTriggerVisibilityToggle() {
    var tabs = document.getElementsByClassName("js_tab-menu__tab");
    var triggerVisibilityHandler = function triggerVisibilityHandler(tab) {
      tab.addEventListener("click", function (_ref) {
        var _target$dataset;
        var target = _ref.target;
        var activeTarget = target === null || target === void 0 || (_target$dataset = target.dataset) === null || _target$dataset === void 0 ? void 0 : _target$dataset.target;
        toggleTriggerVisibility(areAllTabEventsLoaded(activeTarget));
      });
    };
    forEach(tabs, triggerVisibilityHandler);
  }
  function toggleTriggerVisibility(hide) {
    var isHidden = loadMoreTrigger.classList.contains("hidden");
    if (hide && !isHidden) {
      loadMoreTrigger.classList.add("hidden");
    } else if (!hide && isHidden) {
      loadMoreTrigger.classList.remove("hidden");
    }
  }
}