import Dropdown from "./dropdown";
import DropdownMulti from "./dropdownMulti";
var LARGE_CAP = 35207;
export default function winnersLosersFilter() {
  var element = document.getElementsByClassName("js_winners-losers-filter")[0];
  var intervalEl = element === null || element === void 0 ? void 0 : element.getElementsByClassName("js_filter-period")[0];
  var listsEl = element === null || element === void 0 ? void 0 : element.getElementsByClassName("js_filter-lists")[0];
  var sectorsEl = element === null || element === void 0 ? void 0 : element.getElementsByClassName("js_filter-sectors")[0];
  var submitButton = element === null || element === void 0 ? void 0 : element.getElementsByClassName("js_filter-button")[0];
  if (!intervalEl || !listsEl || !sectorsEl || !submitButton) return;
  var intervalDropdown = new Dropdown(intervalEl, closeDropdowns);
  var listsDropdown = new DropdownMulti(listsEl, closeDropdowns);
  var sectorsDropdown = new DropdownMulti(sectorsEl, closeDropdowns);
  listsDropdown.setDropdownTitle();
  sectorsDropdown.setDropdownTitle();
  submitButton.addEventListener("click", function () {
    var params = createQueryParams();
    window.location.href = new URL("?".concat(params), window.location.href);
  });
  function closeDropdowns() {
    intervalDropdown.close();
    listsDropdown.close();
    sectorsDropdown.close();
  }
  function createQueryParams() {
    var params = new URLSearchParams({
      lists: listsDropdown.getSelectedValues().join(",") || LARGE_CAP,
      period: intervalDropdown.getSelectedValue() || "diff1dprc"
    });
    var sectors = sectorsDropdown.getSelectedValues();
    if (sectors.length > 0) {
      params.append("sectors", sectors.join(","));
    }
    return params;
  }
}