function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import forEach from "../../helpers/forEach";
import setupLazyLoad from "../../helpers/setupLazyLoad";
import showMoreNewsItems from "../../helpers/showMoreNewsItems";
export default function marketNews() {
  var newsWrappers = document.getElementsByClassName("js_market-news");
  forEach(newsWrappers, news);
}
function news(el) {
  var navTabs = Array.from(el.getElementsByClassName("js_market-news-tab"));
  var contentTabs = Array.from(el.getElementsByClassName("js_market-news-items"));
  var newsTab = contentTabs[0],
    telegramTab = contentTabs[1],
    pressTab = contentTabs[2],
    recoTab = contentTabs[3];
  var moreNewsBtn = newsTab === null || newsTab === void 0 ? void 0 : newsTab.getElementsByClassName("js_show-more")[0];
  var endpoint = moreNewsBtn !== null && moreNewsBtn !== void 0 && moreNewsBtn.id ? "/get-list-news/?pageType=market&tags=".concat(moreNewsBtn.id) : null;
  showMoreNewsItems(moreNewsBtn, endpoint);
  var moreTelegramBtn = telegramTab === null || telegramTab === void 0 ? void 0 : telegramTab.getElementsByClassName("js_show-more")[0];
  var morePressBtn = pressTab === null || pressTab === void 0 ? void 0 : pressTab.getElementsByClassName("js_show-more")[0];
  var moreRecoBtn = el.getElementsByClassName("js_show-more-reco-btn")[0];
  var allTelegrams = telegramTab && Array.from(telegramTab.getElementsByClassName("js_news-item"));
  var allPress = pressTab && Array.from(pressTab.getElementsByClassName("js_news-item"));
  var allRecommendations = recoTab && Array.from(recoTab.getElementsByClassName("js_recommendations-teaser"));
  moreTelegramBtn === null || moreTelegramBtn === void 0 || moreTelegramBtn.addEventListener("click", function () {
    return showItems(allTelegrams, moreTelegramBtn);
  });
  morePressBtn === null || morePressBtn === void 0 || morePressBtn.addEventListener("click", function () {
    return showItems(allPress, morePressBtn);
  });
  moreRecoBtn === null || moreRecoBtn === void 0 || moreRecoBtn.addEventListener("click", function () {
    return showMore(allRecommendations, moreRecoBtn, "reco");
  });
  navTabs.forEach(function (item) {
    var index = item.dataset.newsTabIndex;
    item.addEventListener("click", function () {
      hideAllTabs(index);
    });
  });
  hideItems();
  function hideAllTabs(except) {
    contentTabs.forEach(function (tab, index) {
      if (index.toString() === except) {
        tab.classList.remove("hidden");
        navTabs[index].classList.add("market-news__tab--active");
      } else {
        tab.classList.add("hidden");
        navTabs[index].classList.remove("market-news__tab--active");
      }
    });
    getUrlParams();
  }
  function showMore(allItems, btn, target) {
    var targetEl = el.getElementsByClassName("js_view-more-".concat(target, "-target"))[0];
    var hiddenItems = allItems.find(function (item) {
      return item.classList.contains("hidden");
    });
    if (hiddenItems) {
      allItems.forEach(function (item, index) {
        if (index < parseInt(hiddenItems.getAttribute("data-index")) + 12) item.classList.remove("hidden");
      });
      setupLazyLoad(targetEl, function () {
        return showMore(allItems, null, target);
      });
    }
    btn === null || btn === void 0 || btn.remove();
  }
  function getUrlParams() {
    var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var url = window.location.pathname;
    var activeTab = contentTabs.findIndex(function (tab) {
      return !tab.classList.contains("hidden");
    });
    if (activeTab === -1 || activeTab === 0) {
      url = page > 0 ? "".concat(url, "?page=").concat(page) : url;
    } else {
      url = page > 0 ? "".concat(url, "?tab=").concat(activeTab, "&page=").concat(page) : "".concat(url, "?tab=").concat(activeTab);
    }
    window.history.pushState("tab", "Title", url);
  }
  function showItems(items, btn) {
    var _iterator = _createForOfIteratorHelper(items),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var item = _step.value;
        item.classList.remove("hidden");
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    btn === null || btn === void 0 || btn.remove();
  }
  function hideItems() {
    contentTabs.forEach(function (tab, index) {
      if (index === 0 || index === 3) return;
      var newsItems = tab.getElementsByClassName("js_news-item");
      var i = 0;
      var _iterator2 = _createForOfIteratorHelper(newsItems),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var item = _step2.value;
          if (i > 25) item.classList.add("hidden");
          i = i + 1;
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    });
  }
}