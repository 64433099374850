import forEach from "./forEach";
import throttle from "./throttle";
export default function navigationScrollEffect() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : document;
  var navigations = context.getElementsByClassName("js_horizontal-navigation");
  forEach(navigations, scrollEffect);
}
function scrollEffect(scrollArea) {
  var baseClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "horizontal-navigation-scroll";
  var wrapper = scrollArea.parentNode;
  if (scrollArea.scrollWidth > scrollArea.clientWidth) {
    setWrapperModifier("".concat(baseClass, "--start"));
  }
  var throttledScrollHandler = throttle(scrollHandler, 50);
  scrollArea.addEventListener("scroll", throttledScrollHandler);
  function scrollHandler() {
    var scrollLeft = scrollArea.scrollLeft,
      scrollWidth = scrollArea.scrollWidth,
      clientWidth = scrollArea.clientWidth;
    if (scrollLeft === 0) {
      setWrapperModifier("".concat(baseClass, "--start"));
    } else if (scrollWidth === scrollLeft + clientWidth) {
      setWrapperModifier("".concat(baseClass, "--end"));
    } else {
      setWrapperModifier("".concat(baseClass, "--middle"));
    }
  }
  function setWrapperModifier(modifier) {
    if (wrapper.classList.contains(modifier)) return;
    wrapper.classList.remove("".concat(baseClass, "--start"), "".concat(baseClass, "--end"), "".concat(baseClass, "--middle"));
    wrapper.classList.add(modifier);
  }
}