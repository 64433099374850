import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { COLOR_BACKGROUND, COLOR_LABELS, COLOR_LINE, COLOR_TOOLTIP_BACKGROUND, FONT_CHART, FONT_TITLE } from "../../helpers/chartConstants";
var COLOR_LEGEND = "#b5b3c1";
var COLOR_LEGEND_HOVER = "#fff";
var COLOR_PRIMARY = "#fff";
var COLOR_SECONDARY = "#1278a9";
var mobileLegend = {};
var mobileTitle = {};
var mobileTitleStyle = {};
if (CHANNEL_MOBILE) {
  mobileLegend.align = "left";
  mobileLegend.width = "50%";
  mobileTitle.align = "left";
  mobileTitleStyle.fontSize = 18;
}
export default function init() {
  var _window$Di$chart;
  if (!window.Highcharts) return;
  var chart = (_window$Di$chart = window.Di.chart) !== null && _window$Di$chart !== void 0 ? _window$Di$chart : {};
  var tomorrow = chart.tomorrow,
    today = chart.today;
  var averageToday = today.reduce(function (a, b) {
    return a + b;
  }) / today.filter(Boolean).length;
  var averageTomorrow = tomorrow !== null && tomorrow !== void 0 && tomorrow.length ? tomorrow.reduce(function (a, b) {
    return a + b;
  }) / tomorrow.filter(Boolean).length : null;
  window.Highcharts.chart("electricityHourlyChart", {
    title: _objectSpread({
      text: chart.title,
      y: 5,
      style: _objectSpread({
        color: COLOR_PRIMARY,
        fontFamily: FONT_TITLE,
        fontSize: 24,
        letterSpacing: -0.5,
        webkitFontSmoothing: "antialiased"
      }, mobileTitleStyle)
    }, mobileTitle),
    credits: {
      enabled: false
    },
    chart: {
      type: "line",
      spacingTop: 40,
      spacingRight: 20,
      spacingLeft: 20,
      backgroundColor: COLOR_BACKGROUND,
      borderWidth: 0
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: " öre/kWh",
      backgroundColor: COLOR_TOOLTIP_BACKGROUND,
      style: {
        fontFamily: FONT_CHART
      }
    },
    xAxis: {
      lineColor: COLOR_LINE,
      gridLineColor: COLOR_LINE,
      tickColor: COLOR_LINE,
      gridLineWidth: 0,
      gridlineDashStyle: "dash",
      labels: {
        style: {
          color: COLOR_LABELS,
          fontFamily: FONT_CHART
        }
      },
      categories: Array(24).fill().map(function (_, i) {
        return "".concat(String(i).padStart(2, "0"), "\u2013").concat(String((i + 1) % 24).padStart(2, "0"));
      })
    },
    yAxis: {
      softMin: 0,
      gridLineColor: COLOR_LINE,
      gridLineWidth: 1,
      labels: {
        style: {
          color: COLOR_LABELS,
          fontFamily: FONT_CHART
        }
      },
      title: {
        enabled: false
      }
    },
    plotOptions: {
      series: {
        fillOpacity: 0.1,
        borderWidth: 0
      },
      line: {
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false
            }
          }
        }
      }
    },
    legend: _objectSpread({
      itemStyle: {
        color: COLOR_LEGEND,
        fontFamily: FONT_CHART
      },
      itemHoverStyle: {
        color: COLOR_LEGEND_HOVER
      }
    }, mobileLegend),
    series: [].concat(_toConsumableArray(tomorrow !== null && tomorrow !== void 0 && tomorrow.length ? [{
      name: "Imorgon",
      data: tomorrow,
      color: COLOR_SECONDARY
    }] : []), [{
      name: "Idag",
      data: today,
      color: COLOR_PRIMARY
    }], _toConsumableArray(tomorrow !== null && tomorrow !== void 0 && tomorrow.length ? [{
      name: "Morgondagens medelpris",
      type: "line",
      data: [[0, averageTomorrow], [23, averageTomorrow]],
      color: COLOR_SECONDARY,
      dashStyle: "LongDash",
      lineWidth: 1,
      marker: {
        enabled: false
      },
      enableMouseTracking: false
    }] : []), [{
      name: "Dagens medelpris",
      type: "line",
      data: [[0, averageToday], [23, averageToday]],
      color: COLOR_PRIMARY,
      dashStyle: "LongDash",
      lineWidth: 1,
      marker: {
        enabled: false
      },
      enableMouseTracking: false
    }])
  });
}