import Dropdown from "./dropdown";
var SCROLLABLE_IDENTIFIER = "--scrollable";
var TABLE_HEADER_CLASS = "instrument-table__header";
export default function fundsFilter() {
  var fundsFilterElement = document.getElementsByClassName("js_funds-filter")[0];
  if (!fundsFilterElement) return;
  var ppmEl = fundsFilterElement.getElementsByClassName("js_funds-filter__ppm")[0];
  var initialEl = fundsFilterElement.getElementsByClassName("js_funds-filter__initial")[0];
  var freeTextSearch = fundsFilterElement.getElementsByClassName("js_funds-filter__query")[0];
  if (!ppmEl || !initialEl || !freeTextSearch) return;
  var clearButton = fundsFilterElement.getElementsByClassName("js_funds-filter__clear-form")[0];
  var cells = Array.from(fundsFilterElement.getElementsByTagName("tr")).filter(function (r) {
    var _r$id;
    return ((_r$id = r.id) === null || _r$id === void 0 ? void 0 : _r$id.indexOf(SCROLLABLE_IDENTIFIER)) <= 0 && !r.classList.contains(TABLE_HEADER_CLASS);
  }).map(function (r) {
    return r.firstChild;
  });
  var rowObejcts = cells.map(function (c) {
    return {
      name: c.innerText.toLowerCase(),
      row: c.parentNode,
      scrollablePart: document.getElementById("".concat(c.parentNode.id).concat(SCROLLABLE_IDENTIFIER))
    };
  });
  var hitsEl = fundsFilterElement.getElementsByClassName("js_funds-filter__hits")[0];
  var filterActivated = false;
  var ppmDropdown = new Dropdown(ppmEl, closeDropdowns, filterTable);
  var initialDropdown = new Dropdown(initialEl, closeDropdowns, filterTable);
  freeTextSearch.addEventListener("keyup", filterTable);
  freeTextSearch.addEventListener("focus", closeDropdowns);
  clearButton.addEventListener("click", function () {
    ppmDropdown.deselectAll();
    initialDropdown.deselectAll();
    freeTextSearch.value = "";
    filterTable();
  });
  function closeDropdowns() {
    ppmDropdown.close();
    initialDropdown.close();
  }
  function filterTable() {
    var _initialDropdown$getS;
    if (!filterActivated) {
      activateFilter();
    }
    closeDropdowns();
    var initial = (_initialDropdown$getS = initialDropdown.getSelectedValue()) === null || _initialDropdown$getS === void 0 ? void 0 : _initialDropdown$getS.toLowerCase();
    var query = freeTextSearch.value.toLowerCase();
    var onlyPPM = ppmDropdown.getSelectedValue() === "true";
    var hits = 0;
    rowObejcts.forEach(function (r) {
      if ((!initial || r.name[0] === initial) && (!query || r.name.indexOf(query) >= 0) && (!onlyPPM || r.row.dataset.ppm === "true")) {
        r.row.classList.remove("hidden");
        if (r.scrollablePart) {
          r.scrollablePart.classList.remove("hidden");
        }
        hits++;
      } else {
        r.row.classList.add("hidden");
        if (r.scrollablePart) {
          r.scrollablePart.classList.add("hidden");
        }
      }
    });
    hitsEl.innerText = "(".concat(hits, ")");
  }
  function activateFilter() {
    var firstTable = fundsFilterElement.getElementsByClassName("js_instrument-table")[0];
    var tableBody = CHANNEL_DESKTOP ? firstTable.getElementsByTagName("tbody")[0] : firstTable.getElementsByClassName("js_instrument-table__fixed-part")[0].getElementsByTagName("tbody")[0];
    var scrollableBody = CHANNEL_DESKTOP ? null : firstTable.getElementsByClassName("js_instrument-table-scroll__scrollable-part")[0].getElementsByTagName("tbody")[0];
    firstTable.setAttribute("data-ad-count", 1);
    firstTable.setAttribute("data-max-data-rows-between", 10000);
    rowObejcts.forEach(function (r) {
      tableBody.appendChild(r.row);
      if (r.scrollablePart) {
        scrollableBody.appendChild(r.scrollablePart);
      }
    });
    var tableChilds = fundsFilterElement.getElementsByClassName("js_instrument-table--child");
    while (tableChilds.length) {
      tableChilds[tableChilds.length - 1].remove();
    }
    var ads = fundsFilterElement.getElementsByClassName("ad");
    while (ads.length > 1) {
      ads[ads.length - 1].remove();
    }
    filterActivated = true;
  }
}